export const useDailyStore = defineStore('daily', {
  state: () => ({
    dailyList: [
      {
        unRead: true,
        date: '2022-12-30',
        text: '[테스트] 하나님이 사랑하는 사람은 무엇을 잃었어도 새롭게 됩니다. 하나님이 사랑하는 사람은 무엇을 잃었어도 새롭게 됩니다.',
        isBlock: false,
        author: {
          isBlock: true,
          imgSrc: 'https://i.pravatar.cc/300',
          name: '유기성',
          id: 'test@test.com'
        },
        likeItCount: 8
      },
      {
        unRead: true,
        date: '2022-12-30',
        text: '[테스트] 한줄',
        isBlock: false,
        author: {
          isBlock: false,
          imgSrc: 'https://i.pravatar.cc/300',
          name: '유기성',
          id: 'test1@test.com'
        },
        likeItCount: 8
      },
      {
        date: '2022-12-30',
        text: '하나님이 사랑하는 사람은 무엇을 잃었어도 새롭게 됩니다.',
        isBlock: false,
        author: {
          isBlock: false,
          imgSrc: 'https://i.pravatar.cc/300',
          name: '유기성',
          id: 'test2@test.com'
        },
        likeItCount: 8
      },
      {
        date: '2022-12-30',
        text: '하나님이 사랑하는 사람은 무엇을 잃었어도 새롭게 됩니다.',
        isBlock: false,
        author: {
          isBlock: false,
          imgSrc: 'https://i.pravatar.cc/300',
          name: '유기성',
          id: 'test3@test.com'
        },
        likeItCount: 8
      },
      {
        date: '2022-12-30',
        text: '하나님이 사랑하는 사람은 무엇을 잃었어도 새롭게 됩니다.',
        isBlock: false,
        author: {
          isBlock: false,
          imgSrc: 'https://i.pravatar.cc/300',
          name: '유기성',
          id: 'test4@test.com'
        },
        likeItCount: 8
      }
    ]
  }),
  actions: {
    deactiveBlock(id) {
      this.dailyList = this.dailyList.map(itm => {
        if (itm.author.id === id) itm.author.isBlock = false;
        return itm;
      });
      //
      /* this.dailyList = this.dailyList.map(itm => {
        return { ...itm, author: { ...itm.author, isBlock: itm.author.id === id ? false : itm.author.isBlock } };
      }); */
      //
    }
  }
});
