<template>
  <v-dialog v-model="props.modalOpen" persistent :fullscreen="isMobile" :max-width="isMobile ? undefined : 360" min-height="480px" scrollable>
    <v-card color="background" class="jw-dialog">
      <v-toolbar dark color="background" class="jw-dialog__header">
        <v-spacer v-if="!isMobile" />
        <v-toolbar-title> 신고하기 </v-toolbar-title>
        <v-toolbar-items>
          <v-btn icon="jwIcons:svg/close" @click="emits('closeModal')"></v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <VCardText class="jw-dialog__body pa-5 text-center">
        <v-radio-group v-model="selectComplain" class="jw-radio jw-radio--style2 d-inline-flex mt-5">
          <!-- <v-radio density="compact" value="COMPLAIN_1" label="음란, 청소년 유해"></v-radio>
          <v-radio density="compact" value="COMPLAIN_2" label="이단교리전파, 파교행위" class="mt-2"></v-radio>
          <v-radio density="compact" value="COMPLAIN_3" label="욕설, 비방, 차단, 혐오" class="mt-2"></v-radio>
          <v-radio density="compact" value="COMPLAIN_4" label="도배, 스팸" class="mt-2"></v-radio>
          <v-radio density="compact" value="COMPLAIN_5" label="홍보, 영리목적" class="mt-2"></v-radio>
          <v-radio density="compact" value="COMPLAIN_6" label="개인정보 노출, 유포, 거래" class="mt-2"></v-radio>
          <v-radio density="compact" value="COMPLAIN_7" label="불법정보" class="mt-2"></v-radio>
          <v-radio density="compact" value="COMPLAIN_8" label="권리침해 신고" class="mt-2"></v-radio>
          <v-radio density="compact" value="COMPLAIN_9" label="기타" class="mt-2"></v-radio> -->
          <v-radio density="compact" value="음란, 청소년 유해" label="음란, 청소년 유해"></v-radio>
          <v-radio density="compact" value="이단교리전파, 파교행위" label="이단교리전파, 파교행위" class="mt-2"></v-radio>
          <v-radio density="compact" value="욕설, 비방, 차단, 혐오" label="욕설, 비방, 차단, 혐오" class="mt-2"></v-radio>
          <v-radio density="compact" value="도배, 스팸" label="도배, 스팸" class="mt-2"></v-radio>
          <v-radio density="compact" value="홍보, 영리목적" label="홍보, 영리목적" class="mt-2"></v-radio>
          <v-radio density="compact" value="개인정보 노출, 유포, 거래" label="개인정보 노출, 유포, 거래" class="mt-2"></v-radio>
          <v-radio density="compact" value="불법정보" label="불법정보" class="mt-2"></v-radio>
          <v-radio density="compact" value="권리침해 신고" label="권리침해 신고" class="mt-2"></v-radio>
          <v-radio density="compact" value="기타" label="기타" class="mt-2"></v-radio>
        </v-radio-group>
        <div class="d-flex justify-center mt-10">
          <VBtn variant="flat" rounded color="secondary" class="px-4" @click="onClickComplain">신고하기</VBtn>
        </div>
      </VCardText>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { useDisplay } from "vuetify";
import { useMemberStore } from "~/store/member";
const { smAndDown, smAndUp, xlAndUp } = useDisplay();
const isMobile = computed(() => smAndDown.value);

const memberStore = useMemberStore();

const props = defineProps({
  modalOpen: {
    type: Boolean,
    default: false,
  },
  //신고용 diaryCd, gubun 추가
  diaryCd: {
    type: String,
  },
  gubun: {
    type: String,
  },
  memCd: String,
});
const selectComplain = ref(""); // 신고하기 정보

const emits = defineEmits(["closeModal"]);

async function onClickComplain() {
  try {
    const { data: res = {} } = await memberStore.reportPostActor({ diaryCd: props.memCd, gubun: props.gubun, reason: selectComplain.value });
    if (res?.result == 0 || !res?.result) alert("신고에 실패했습니다.\n잠시 후 다시 시도해주세요.");
    else if (res?.result == 1) alert("신고가 접수되었습니다.\n요청하신 신고내용을 검토하여 조치하도록 하겠습니다.");
    else if (res?.result == 2) alert("이미 신고하셨습니다.");

    emits("closeModal");
    return;
  } catch (err) {
    console.error(`onClickComplain err : `, err);
  }
}
</script>

<style scoped lang="scss"></style>
