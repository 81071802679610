<template>
  <v-dialog
    v-model="props.modalOpen"
    scrollable
    :fullscreen="isMobile"
    :max-width="isMobile ? undefined : 540"
    min-height="480px"
    @click:outside="onClickOutside"
  >
    <v-card color="background" class="jw-dialog">
      <v-toolbar dark color="background" class="jw-dialog__header">
        <v-spacer />
        <v-toolbar-items>
          <v-btn icon="jwIcons:svg/close" @click="emits('modalClose')"></v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <VCardText class="jw-dialog__body pa-5 pt-4" style="height: 400px">
        <div class="jw-author-dialog">
          <div class="jw-author-dialog__header">
            <v-btn-toggle variant="outlined" divided density="compact" class="jw-btn-group" v-if="!isMyInfo">
              <v-btn size="small" data-text="차단하기" @click.stop="onClickBlockAction">{{ $t("dwj.new.msg.30503") }}</v-btn>
              <v-btn size="small" data-text="신고하기" @click.stop="state.abuseModalOpen = true">{{ $t("dwj.new.msg.30502") }}</v-btn>
            </v-btn-toggle>
          </div>
          <div class="jw-author-dialog__body">
            <div class="name">
              {{ props.name || props.memName }}
            </div>

            <div class="jw-more-userinfo__avatar" @click.stop="modifyAvatar">
              <v-avatar class="jw-author__thumbs jw-post-info" size="140">
                <VImg v-if="props.isBlock" src="~/assets/images/block_profile.svg" />
                <VImg v-else-if="isMyInfo" :src="`https://jwj.kr/upload/member/${authStore.userInfo.profileId}`" />
                <VImg v-else-if="!!props.imgSrc" :src="props.imgSrc" />
                <span v-else-if="!!props.memName" class="initial">
                  {{ displayName(props.memName) }}
                </span>
              </v-avatar>
            </div>
            <input ref="uploadAvatar" class="d-none" type="file" accept="image/*" @change="onChangeAvatarFile" />

            <!-- <div class="desc" v-html="state.updatedPledge || props.myPledge" v-if="!myPledgeEditMode" /> -->
            <!-- <div class="desc" v-html="state.updatedPledge || props.myPledge" v-if="!isMyInfo" /> -->
            <div class="desc" v-html="props.myPledge || state.updatedPledge" v-if="!isMyInfo" />
            <div class="pt-3">
              <!-- <VTextarea v-if="isMyInfo && myPledgeEditMode" rows="2" maxlength="100" hide-details v-model:model-value="state.updatedPledge" /> -->
              <VTextarea
                v-if="isMyInfo"
                variant="outlined"
                rows="3"
                maxlength="100"
                hide-details
                v-model:model-value="state.updatedPledge"
                class="jw-textarea--profile"
              />
            </div>

            <div class="pt-5 pb-12 editBtn" v-if="isMyInfo" @click.stop="editMyPledgeActor">수정</div>

            <div class="pt-8 pb-12" v-if="isManager">
              <VBtn color="#FF3D00" rounded height="28" @click.stop="onClickManagerBlock">전체 숨김</VBtn>
            </div>
          </div>
        </div>
      </VCardText>
    </v-card>
    <JwAbuseReport :modalOpen="state.abuseModalOpen" @closeModal="state.abuseModalOpen = false" v-bind="{ gubun: 't_mem', memCd: props.memCd }" />
    <!-- <PledgeEditModal :pledgeEdit="state.pledgeEdit" :pledgeEditModalActor :content="props.myPledge" /> -->
  </v-dialog>
</template>

<script setup>
import JwAbuseReport from "./JwAbuseReport.vue";
import PledgeEditModal from "./PledgeEditModal.vue";
import { useCommonStore } from "~/store/common";
import { useMemberStore } from "~/store/member";
import { useAuthStore } from "~/store/auth";
import { useMoreStore } from "~/store/more";
import { displayName } from "~/utils/utils";

import { useDisplay } from "vuetify";

const { smAndDown } = useDisplay();
const isMobile = computed(() => smAndDown.value);

const commonStore = useCommonStore();
const memberStore = useMemberStore();
const authStore = useAuthStore();
const moreStore = useMoreStore();

const isMyInfo = computed(() => {
  return authStore.userInfo.memCd === props.memCd;
});

const myPledgeEditMode = ref(false);

const props = defineProps({
  modalOpen: {
    type: Boolean,
    default: false,
  },
  isBlock: {
    type: Boolean,
    default: false,
  },
  imgSrc: {
    type: [String, Boolean],
  },
  size: {
    type: [String, Number],
    default: 20,
  },
  name: {
    type: [String, Boolean],
  },
  memName: {
    type: String,
  },
  //신고용 diaryCd, gubun 추가
  diaryCd: {
    type: String,
    default: "",
  },
  gubun: {
    type: String,
    default: "",
  },
  //차단하기용 memCd 추가
  memCd: {
    type: String,
  },
  myPledge: String,
});

const abuseObj = reactive({ diaryCd: "", gubun: "", memCd: "" });

const isManager = computed(() => {
  return authStore.userInfo.grade == "manager";
});

const state = reactive({
  abuseModalOpen: false,
  pledgeEdit: false,
  updatedPledge: "",
});

function pledgeEditModalActor(boolean, updatedPledge = "") {
  if (updatedPledge) state.updatedPledge = updatedPledge;
  else state.updatedPledge = "";
  state.pledgeEdit = boolean;
}

const emits = defineEmits(["modalClose"]);

watch(
  () => props.modalOpen,
  (v) => {
    if (v == true) {
      if (isMyInfo.value) {
        state.updatedPledge = props.myPledge;
      }
    }
  }
);

async function onClickBlockAction() {
  try {
    //memCd, delYN(해제할 경우에만 N으로 보낸다.)
    /* if (!confirm(`차단하기 \n 차단하실 경우 이 사용자의 모든 글은 숨겨집니다`)) return false;
    await memberStore.blockingUserActor({ memCd: props.memCd }); */
    //commonStore.setConfirmDialog(true);
    commonStore.setConfirmDialog({
      open: true,
      title: "차단하기",
      text: "차단하실 경우 이 사용자의 모든 글은<br/>숨겨집니다.",
      positive: {
        name: "확인",
        actor: async () => {
          await memberStore.blockingUserActor({ memCd: props.memCd });
          location.reload(true);
        },
      },
      negative: { name: "취소" },
    });
  } catch (err) {
    console.error(`onClickBlockAction err : `, err);
  }
}

async function addBlack() {
  try {
    const res = await memberStore.insertBlackListActor({ memCd: authStore.userInfo.memCd });

    return res;
  } catch (err) {
    console.error(`addBlack err : `, err);
  }
}

async function onClickManagerBlock() {
  try {
    if (confirm("전체 숨김을 하시겠습니까?") === false) return false;

    const res = await memberStore.insertBlackListActor({ memCd: props.memCd });
    // 전체 숨김 완료 후 처리하기
    location.reload(true);
  } catch (err) {
    console.error(`onClickManagerBlock err : `, err);
  }
}

function onClickOutside() {
  emits("modalClose");
}

let uploadAvatar = ref(null);
const avatarImageUrl = ref(null);
async function modifyAvatar() {
  if (!isMyInfo.value) return;
  uploadAvatar.value.click();
}

const myInfo = reactive({
  profilePhoto: "",
});

async function onChangeAvatarFile(e) {
  try {
    const [file] = e.target.files;
    if (file === "") return;

    avatarImageUrl.value = URL.createObjectURL(file);
    const res = await moreStore.uploadAvatarImage({
      fileUploadInfo: file,
    });

    //if (res.result == 1) user.profilePhoto = res.profilePhoto;
    if (res.result == 1) {
      /* props.imgSrc = `https://jwj.kr/upload/member/${res.profilePhoto}`;
      authStore.userInfo.profileId = `${res.profilePhoto}`; */
      /* const { data: res = {} } = await commonStore.getMemInfoActor({ memCd: props.memCd || authStore.userInfo.memCd });
      console.log("myInfo :: ", res.result); */
      await authStore.chkLoginStatusActor();
    } else return alert("프로필 사진 변경에 실패했습니다.");
  } catch (err) {
    console.error(`onChangeAvatarFile err : `, err);
  }
}

function openPledgeEditArea() {
  state.updatedPledge = props.myPledge;
  myPledgeEditMode.value = true;
}

async function editMyPledgeActor() {
  try {
    const res = await moreStore.updateSettingInfo({
      memCd: authStore.userInfo.memCd,
      myPledge: state.updatedPledge,
    });
    alert("나의 다짐이 수정되었습니다.");
    return res;
  } catch (err) {
    alert("나의 다짐 수정에 실패했습니다. 다시 시도해주세요.");
    console.error(`editMyPledgeActor err : `, err);
  }
}
</script>

<style scoped lang="scss">
.jw-author__thumbs .initial {
  font-size: 5.75em !important;
  height: inherit !important;
  background: #2da562;
  color: #ffffff;
}

.editBtn {
  color: #828282;
  text-decoration: underline;
}

.v-textarea {
  background-color: #fffcf8 !important;
}
.v-textarea:focus {
  background-color: #fffcf8 !important;
}
</style>
